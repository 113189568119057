<template>
    <v-container grid-list-xs>
        <v-layout>
            <v-flex>
                Post comming soon...
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    data(){
        return{
        }
    }
}
</script>